<template>
    <b-overlay :show="loadingData" rounded="sm">
        <div>
            <b-card no-body class="mb-4">
                <template v-if="!readOnly">
                    <Header />
                    <hr class="mb-2" />
                </template>

                <div class="row mb-4">
                    <div class="col-4">
                        <p>
                            <strong>Last screening completition date:</strong>
                            {{ $formatDate(screeningsBatch.lastCompletionDate) }}
                        </p>
                        <p><strong>Days since last screening date:</strong> {{ daysLastScreeningDate }}</p>
                        <p><strong>Requires new screening:</strong> {{ requiresNewScreening }}</p>
                    </div>

                    <div class="col-4">
                        <p><strong>Screening send date:</strong> {{ $formatDate(screeningsBatch.date) }}</p>
                        <p>
                            <strong>Screening status: </strong>

                            <b-badge :variant="screningStatusColor(screeningsBatch.state)">{{
                                screeningsBatch.state
                            }}</b-badge>
                        </p>
                    </div>
                    <div class="col-4 d-flex justify-content-end align-items-start">
                        <b-button
                            variant="primary"
                            class="mr-2"
                            :disabled="loadingSendVerifications"
                            @click="validateSubmitToVerification"
                            v-if="$validatePermission('/ScreeningsRequest-SubmitForVerifications')"
                        >
                            <b-spinner small v-if="loadingSendVerifications" class="mr-2" />
                            <span>Submit for screening</span>
                        </b-button>
                    </div>
                </div>

                <div class="mb-2 d-flex justify-content-between">
                    <b-form-group class="mb-2 w-25">
                        <b-input-group>
                            <b-form-input
                                size="sm"
                                id="filter-input"
                                v-model="query"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>

                            <b-input-group-append>
                                <b-button variant="danger" size="sm" :disabled="!query" @click="query = ''"
                                    >Clear</b-button
                                >
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </div>

                <div>
                    <div class="grid border">
                        <div class="bg-light" v-for="field in lvl1Fields" :key="field.key">
                            <div class="p-2" style="font-weight: bold">{{ field.label }}</div>
                        </div>
                    </div>
                    <div class="grid border" v-for="(item, idx) in filteredScreenings" :key="idx">
                        <div class="" v-for="field in lvl1Fields" :key="field.key">
                            <div class="p-2">
                                <span v-if="field.key === 'action'">
                                    <b-button v-b-toggle="`accordion-${idx}`" variant="primary" size="sm"
                                        >Toggle Details</b-button
                                    >
                                </span>
                                <span v-else-if="field.key === 'date'">
                                    {{ $formatDate(item.screeningsBatch[field.key]) }}
                                </span>
                                <span v-else-if="field.key === 'lastCompletionDate'">
                                    {{ $formatDate(item.screeningsBatch[field.key]) }}
                                </span>
                                <span v-else-if="field.key === 'state'">
                                    <b-badge :variant="screningStatusColor(item.screeningsBatch[field.key])">{{
                                        item.screeningsBatch[field.key]
                                    }}</b-badge>
                                </span>
                                <span v-else-if="field.key === 'pending'">
                                    {{ countPendingScreenings(item.screeningsRecords) }}
                                </span>
                                <span v-else>
                                    <template v-if="item.screeningsBatch[field.key]">{{
                                        item.screeningsBatch[field.key]
                                    }}</template>
                                    <template v-else>N/A</template>
                                </span>
                            </div>
                        </div>
                        <b-collapse
                            :id="`accordion-${idx}`"
                            :visible="idx == 0"
                            class="w-100"
                            style="grid-column: span 9 / span 9"
                        >
                            <div class="px-3 pb-2">
                                <h6 class="mt-2 ml-2">Screening {{ $formatDate(item.screeningsBatch.date) }}</h6>
                                <b-table
                                    :items="item.screeningsRecords"
                                    :fields="lvl2Fields"
                                    small
                                    responsive
                                    head-variant="light"
                                    hover
                                >
                                    <template #cell(createDate)="data">
                                        {{ $formatDate(data.item.createDate) }}
                                    </template>
                                    <template #cell(formCompletedDate)="data">
                                        {{ $formatDate(data.item.formCompletedDate) }}
                                    </template>
                                    <template #cell(amount)="data"> {{ $formatCurrency(data.item.amount) }} </template>
                                    <template #cell(montlyPayment)="data">
                                        {{ $formatCurrency(data.item.montlyPayment) }}
                                    </template>

                                    <template v-slot:cell(isGuarantor)="data">
                                        <b-badge :variant="data.item.isGuarantor ? 'success' : 'danger'">{{
                                            data.item.isGuarantor ? 'Yes' : 'No'
                                        }}</b-badge>
                                    </template>
                                    <template #cell(formIsCompleted)="data">
                                        <b-badge variant="success" v-if="data.item.formIsCompleted">Completed</b-badge>
                                        <b-badge variant="danger" v-else>Pending</b-badge>
                                    </template>
                                </b-table>
                            </div>
                        </b-collapse>
                    </div>
                </div>

                <b-modal id="modal-add-screening" hide-footer centered title="Financial Obligations" size="md">
                    <b-card no-body>
                        <p>Are you sure you want to add a new screening for this customer?</p>
                        <div class="d-flex justify-content-end">
                            <b-button variant="outline-secondary" @click="closeModal" class="mr-2">Cancel</b-button>
                        </div>
                    </b-card>
                </b-modal>
            </b-card>

            <!-- Modal with to ask spouses to screenig request -->
            <b-modal
                v-if="spouse"
                id="modal-confirm-spouses"
                hide-footer
                centered
                title="Confirm Screening Submission"
                size="md"
            >
                <b-card no-body v-if="spouse">
                    <p class="mb-1">You're about to submit this client for screening.</p>
                    <p class="mb-3">
                        <strong>Spouse: </strong>{{ spouse.firstName }} {{ spouse.middleName }} {{ spouse.lastName }}
                    </p>
                    <p class="mb-1">
                        <strong>Last Screening Completion Date: </strong>
                        <template v-if="spouse.screenings.screeningsBatch">{{
                            $formatDate(spouse.screenings.screeningsBatch.lastCompletionDate)
                        }}</template>
                    </p>
                    <p class="mb-1"><strong>Days Since Last Screening:</strong> {{ spouseDaysLastScreeningDate }}</p>
                    <p class="mb-1">
                        <strong>Requires New Screening:</strong>
                        {{ spouseRequiresNewScreening }}
                    </p>
                    <template>
                        <h5 class="mt-3">Submission Status</h5>
                        <p class="mb-1">
                            <strong>Screening Sent Date: </strong>
                            <template v-if="spouse.screenings.screeningsBatch">{{
                                $formatDate(spouse.screenings.screeningsBatch.date)
                            }}</template>
                        </p>
                        <p class="mb-1">
                            <strong>Screenig Status: </strong>
                            <b-badge
                                v-if="spouse.screenings.screeningsBatch"
                                :variant="spouse.screenings.screeningsBatch.state === 'Pending' ? 'danger' : 'success'"
                                >{{ screeningsBatch.state }}</b-badge
                            >
                        </p>
                    </template>

                    <b-form @submit.prevent="submitToVerification">
                        <label for="spouse" class="mt-4">Add spouse to screening?</label>
                        <b-form-group>
                            <b-form-radio-group
                                id="spouse"
                                v-model="spouseSelection"
                                :options="[
                                    {
                                        text: 'Yes',
                                        value: true,
                                    },
                                    {
                                        text: 'No',
                                        value: false,
                                    },
                                ]"
                                required
                            ></b-form-radio-group>
                        </b-form-group>

                        <div class="d-flex justify-content-end">
                            <b-button variant="primary" :disabled="loadingSendVerifications" type="submit">
                                <b-spinner small v-if="loading"></b-spinner>
                                <span v-else>Submit</span>
                            </b-button>
                        </div>
                    </b-form>
                </b-card>
            </b-modal>
        </div>
    </b-overlay>
</template>

<script>
import Header from '@/components/Header.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'CustomerScreening',
    components: {
        Header,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        query: null,
        fields: [
            {
                key: 'id',
                label: 'ID',
            },
            {
                key: 'createDate',
                label: 'Request Date',
            },
            {
                key: 'bankDescription',
                label: 'Bank',
            },

            {
                key: 'formCompletedDate',
                label: 'Date Completed',
            },
            {
                key: 'amount',
                label: 'Balance',
            },
            {
                key: 'montlyPayment',
                label: 'Monthly Payment',
            },
            {
                key: 'loanStatus',
                label: 'Loan Status',
            },
            {
                key: 'formIsCompleted',
                label: 'Status',
            },
            {
                key: 'comment',
                label: 'Comments',
            },
        ],

        lvl1Fields: [
            {
                key: 'id',
                label: 'ID',
            },
            {
                key: 'date',
                label: 'Request Date',
            },
            {
                key: 'lastCompletionDate',
                label: 'Last Completion Date',
            },
            {
                key: 'state',
                label: 'State',
            },
            {
                key: 'pending',
                label: 'Pending',
            },
            {
                key: 'action',
                label: 'Actions',
            },
        ],
        lvl2Fields: [
            {
                key: 'id',
                label: 'ID',
            },
            {
                key: 'createDate',
                label: 'Request Date',
            },
            {
                key: 'bankDescription',
                label: 'Bank',
            },
            {
                key: 'formCompletedDate',
                label: 'Date Completed',
            },
            {
                key: 'amount',
                label: 'Balance',
            },
            {
                key: 'montlyPayment',
                label: 'Monthly Payment',
            },
            {
                key: 'isGuarantor',
                label: 'Is Guarantor',
            },
            {
                key: 'loanStatus',
                label: 'Loan Status',
            },
            {
                key: 'formIsCompleted',
                label: 'Status',
            },
            {
                key: 'comment',
                label: 'Comments',
            },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        loadingSendVerifications: false,
        banks: [],
        bank: null,
        obligationtype: null,
        obligationdate: '',
        amount: null,
        monthlypayment: null,
        comment: '',
        loading: false,
        loadingData: false,
        spouse: null,
        spouseSelection: null,
        screenings: [],
    }),
    methods: {
        async getScreenings() {
            this.loadingData = true;
            const response = await this.$store.dispatch(
                'customer/screening/get_screeningByCustomerId',
                this.$route.params.customer,
            );
            this.screenings = response;

            this.loadingData = false;
        },

        async getSpouseInfo() {
            const spousesId = this.customer?.additionalDetails?.spousesId;
            if (spousesId) {
                const response = await this.$store.dispatch('customer/get_reference', spousesId);
                this.spouse = response;
            }
        },
        async submitToVerification() {
            this.loadingSendVerifications = true;
            await this.$store.dispatch('customer/screening/submit_toPendingRequests', {
                customerId: this.$route.params.customer,
                addSposesToScreenings: this.spouseSelection,
                sposesId: this.spouse?.id,
            });
            await this.$store.dispatch('customer/get_customer', this.$route.params.customer);
            this.loadingSendVerifications = false;
            this.$bvModal.hide('modal-confirm-spouses');
        },

        validateSubmitToVerification() {
            if (this.spouse) {
                this.$bvModal.show('modal-confirm-spouses');
            } else {
                this.submitToVerification();
            }
        },

        clearValues() {
            this.bank = null;
            this.obligationtype = null;
            this.obligationdate = '';
            this.amount = null;
            this.monthlypayment = null;
            this.comment = '';
        },

        closeModal() {
            this.$bvModal.hide('modal-add-screening');
            this.clearValues();
        },

        screningStatusColor(status) {
            if (status === 'Completed') {
                return 'success';
            }
            if (status === 'Pending') {
                return 'secondary';
            }
            return 'danger';
        },

        async saveData() {
            this.loading = true;
            const payload = {
                customerId: this.$route.params.customer,
            };

            await this.$store.dispatch('customer/screening/add_screeningBankRecords', payload);
            await this.$store.dispatch('customer/get_customer', this.$route.params.customer);

            this.loading = false;
            this.$bvModal.hide('modal-add-screening');
            this.clearValues();
        },

        calculateDaysLastScreeningDate(lastCompletionDate) {
            if (!lastCompletionDate) {
                return 0;
            }
            const lastScreeningDate = new Date(lastCompletionDate);
            const currentDate = new Date();
            const diffTime = Math.abs(currentDate - lastScreeningDate);
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
        },
        countPendingScreenings(screenings) {
            return screenings.filter((screening) => screening.formIsCompleted === false).length;
        },
    },
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
        }),
        filteredScreenings() {
            if (!this.query) return this.screenings;

            return this.screenings.map((screening) => {
                const screeningsRecords = screening.screeningsRecords.filter((record) => {
                    return Object.values(record).some((value) => {
                        return String(value).toLowerCase().includes(this.query.toLowerCase());
                    });
                });

                return {
                    ...screening,
                    screeningsRecords,
                };
            });
        },

        screeningsBatch() {
            return (
                this.customer?.screenings?.screeningsBatch || {
                    lastCompletionDate: null,
                    date: null,
                    state: '',
                }
            );
        },

        daysLastScreeningDate() {
            if (!this.screeningsBatch) return 0;
            return this.calculateDaysLastScreeningDate(this.screeningsBatch.lastCompletionDate);
        },

        spouseDaysLastScreeningDate() {
            if (!this.spouse?.screenings?.screeningsBatch) return 0;
            return this.calculateDaysLastScreeningDate(this.spouse.screenings.screeningsBatch.lastCompletionDate);
        },
        requiresNewScreening() {
            if (!this.customer?.screenings?.screeningsBatch || this.screeningsBatch?.state === 'Expired') {
                return 'Yes';
            }

            return this.daysLastScreeningDate > 14 ? 'Yes' : 'No';
        },

        spouseRequiresNewScreening() {
            if (!this.spouse?.screenings?.screeningsBatch) return 'Yes';
            if (this.spouseDaysLastScreeningDate == 0) {
                return 'No';
            }
            return this.spouseDaysLastScreeningDate > 14 ? 'Yes' : 'No';
        },
    },
    created() {
        this.getSpouseInfo();
        this.getScreenings();
    },
};
</script>

<style scoped>
.grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
}
</style>
