<template>
    <b-card no-body class="d-flex flex-column p-3" style="row-gap: 16px">
        <template>
            <Header />
            <hr class="m-0" />
        </template>
        <b-form @submit.prevent="saveData" class="border-bottom pb-4">
            <b-row>
                <b-col cols="12" sm="6">
                    <div class="d-flex justify-between align-items-center mb-4" style="gap: 2px">
                        <label class="mb-0 mr-2">Previous Risk Rating:</label>
                        <b-badge
                            :style="{
                                background: customer.riskTypeColor || '#dfe6e9',
                                color: customer.riskTypeDescription == 'HIGH' ? 'white' : 'black',
                            }"
                            class="text-capitalize"
                            style="text-wrap: nowrap; font-weight: 700"
                            >{{ customer.riskTypeDescription || 'Pending Review' }}</b-badge
                        >
                    </div>
                </b-col>
                <b-col cols="12" sm="6">
                    <div class="d-flex justify-between align-items-center mb-4" style="gap: 2px">
                        <label class="mb-0 mr-2">Next Review Date:</label>
                        {{ $formatDate(customer.scansNextVerificationDate) }}
                    </div>
                </b-col>

                <b-col cols="6" md="3">
                    <div class="d-flex justify-content-between">
                        <label>Deceased</label>
                        <b-form-checkbox v-model="deceased" :disabled="!hasPermissionCheckbox"></b-form-checkbox>
                    </div>
                </b-col>
                <b-col cols="6" md="3">
                    <b-form-group label="Deceased  Comment">
                        <b-form-textarea
                            v-model="deceasedComment"
                            :disabled="!deceased || !hasPermissionCheckbox"
                            required
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>

                <b-col cols="6" md="3">
                    <div class="d-flex justify-content-between">
                        <label>BlackList</label>
                        <b-form-checkbox v-model="blacklist" :disabled="!hasPermissionCheckbox"></b-form-checkbox>
                    </div>
                </b-col>
                <!-- Add comment -->
                <b-col cols="6" md="3">
                    <b-form-group label="BlackList Comment">
                        <b-form-textarea
                            v-model="blacklistComment"
                            :disabled="!blacklist || !hasPermissionCheckbox"
                            required
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" md="3">
                    <div class="d-flex justify-content-between">
                        <label>Politically Exposed Person</label>
                        <b-form-checkbox v-model="pep" :disabled="!hasPermissionCheckbox"></b-form-checkbox>
                    </div>
                </b-col>
                <!-- Add comment -->
                <b-col cols="6" md="3">
                    <b-form-group label="PEP Comment">
                        <b-form-textarea
                            v-model="pepComment"
                            required
                            :disabled="!pep || !hasPermissionCheckbox"
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6 d-flex justify-content-end">
                    <b-button type="submit" variant="primary" :disabled="loading">Save</b-button>
                </b-col>
            </b-row>
        </b-form>

        <div class="border-bottom pb-4">
            <b-row class="mb-4">
                <b-col cols="3">
                    <div class="d-flex justify-content-between">
                        <label>Conduct a New Review</label>
                        <b-form-checkbox v-model="conduct"></b-form-checkbox>
                    </div>
                </b-col>
            </b-row>

            <b-row v-if="conduct" class="mb-4">
                <b-col cols="12" md="6" lg="4">
                    <div class="d-flex justify-content-between align-items-center mb-4">
                        <label class="mb-0 mr-2">Start Review</label>
                        <b-button
                            variant="primary"
                            size="sm"
                            @click="verifyClient"
                            :disabled="
                                loading ||
                                (scanSelected && !scanSelected.reviewCompleted) ||
                                !$validatePermission('/Customer-ComplianceAndRisk-Create-Review')
                            "
                        >
                            <b-spinner small v-if="loading"></b-spinner>
                            Verify client
                        </b-button>
                    </div>
                </b-col>
            </b-row>

            <form @submit.prevent="updateReview" v-if="scanSelected && !scanSelected.reviewCompleted">
                <b-row class="mb-4">
                    <div class="col-6">
                        <div>
                            <h5>1. Is the result a True Match for our client?</h5>
                            <b-form-radio-group
                                v-model="data.isTrueMatch"
                                :options="[
                                    { text: 'True Coincidence', value: true },
                                    { text: 'Non-Truthful Coincidence', value: false },
                                ]"
                                @change="changeReview"
                            />
                        </div>
                    </div>
                </b-row>

                <b-row class="mb-4">
                    <b-col cols="4">
                        <div>
                            <h5>2. Is client a Politically Exposed Person?</h5>
                            <b-form-radio-group
                                v-model="data.pep"
                                :options="[
                                    { text: 'Yes', value: true },
                                    { text: 'No', value: false },
                                ]"
                                @change="changeReview"
                            />
                        </div>
                    </b-col>
                    <b-col cols="6" v-if="data.pep">
                        <div class="">
                            <h5>Relation to PEP</h5>
                            <b-form-radio-group
                                v-model="data.pepRelationTypeId"
                                :options="[
                                    { text: 'None', value: 1 },
                                    { text: 'Self', value: 2 },
                                    { text: 'Family Member', value: 3 },
                                    { text: 'Close Associate', value: 4 },
                                ]"
                            />
                        </div>
                    </b-col>
                </b-row>

                <b-row class="mb-4">
                    <b-col cols="4">
                        <h5>3. Risk Level</h5>
                        <div class="d-flex justify-between align-items-center" style="gap: 2px">
                            <p class="mb-0 mr-2">Previous Risk Rating:</p>
                            <b-badge
                                :style="{
                                    background: customer.riskTypeColor,
                                    color: customer.riskTypeDescription == 'HIGH' ? 'white' : 'black',
                                }"
                                class="text-capitalize"
                                style="text-wrap: nowrap; font-weight: 700"
                                >{{ customer.riskTypeDescription }}</b-badge
                            >
                        </div>

                        <label for="">New Risk Rating </label>
                        <b-form-select
                            v-model="data.riskTypeId"
                            size="sm"
                            placeholder="Select Risk Rating"
                            :options="[
                                { text: 'Select an option', value: null },
                                { text: 'Low', value: 1 },
                                { text: 'Medium', value: 2 },
                                { text: 'High', value: 3 },
                            ]"
                            disabled
                            @change="changeRiskType"
                            required
                        ></b-form-select>
                        <label>Comments/Decision:</label>
                        <b-form-textarea
                            v-model="data.riskTypeDecisionComments"
                            placeholder="Comments/Decision"
                        ></b-form-textarea>
                        <b-form-checkbox class="my-3" v-model="data.requiresSupervisorReview"
                            >Supervisor Review Required</b-form-checkbox
                        >
                        <div
                            class="d-flex justify-content-end"
                            style="gap: 8px"
                            v-if="scanSelected.modifiedDate == null"
                        >
                            <b-button variant="primary" size="sm" type="submit" :disabled="loading"
                                >Submit Review</b-button
                            >
                        </div>
                    </b-col>
                </b-row>
            </form>
        </div>

        <div
            class="border-bottom pb-4"
            v-if="
                data.requiresSupervisorReview &&
                scanSelected &&
                !scanSelected.reviewCompleted &&
                $validatePermission('/Customer-ComplianceAndRisk-Supervisor-Section')
            "
        >
            <h5>Supervisor review section</h5>
            <b-row>
                <b-col cols="6">
                    <p>Current risk rating:</p>
                    <label>New risk rating</label>
                    <b-form-select
                        v-model="data.riskTypeId"
                        size="sm"
                        :options="[
                            { text: 'Select an option', value: null },
                            { text: 'Low', value: 1 },
                            { text: 'Medium', value: 2 },
                            { text: 'High', value: 3 },
                        ]"
                    ></b-form-select>

                    <label>Comments/Decision:</label>
                    <b-form-textarea
                        v-model="data.supervisorReviewComments"
                        placeholder="Comments/Decision"
                    ></b-form-textarea>

                    <div class="d-flex justify-content-end">
                        <b-button variant="primary" class="mt-2" size="sm" @click="completeReview" :disabled="loading"
                            >Complete Review and Update AML Status</b-button
                        >
                    </div>
                </b-col>
            </b-row>
        </div>

        <b-row class="py-4">
            <b-col cols="12">
                <b-table
                    small
                    show-empty
                    striped
                    borderless
                    emp
                    :items="scans"
                    :fields="[
                        { key: 'scanId', label: 'ID' },
                        { key: 'dateReview', label: 'Review Date' },
                        { key: 'riskTypeDescription', label: 'Risk Description' },
                        { key: 'matchedNumber', label: 'Matched Number' },
                        { key: 'reviewCompleted', label: 'Status' },
                        { key: 'riskTypeDecisionComments', label: 'Comments' },
                        { key: 'supervisorReviewComments', label: 'Supervisor Comments' },
                        { key: 'reportURL', label: 'View Report' },
                    ]"
                >
                    <template v-slot:cell(dateReview)="data">{{ $formatDate(data.value) }}</template>

                    <template v-slot:cell(reviewCompleted)="data">
                        <b-badge :variant="data.value ? 'success' : 'danger'" class="text-capitalize">{{
                            data.value ? 'completed' : 'pending'
                        }}</b-badge>
                    </template>

                    <template v-slot:cell(reportURL)="data">
                        <!-- <b-button
                            variant="primary"
                            :href="data.value"
                            :disabled="!data.value"
                            target="_blank"
                            style="text-wrap: nowrap"
                            >View Report</b-button
                        > -->

                        <!-- DOCUMENT JWT SIGNED -->
                        <b-button
                            variant="primary"
                            @click="getReport(data.item)"
                            :disabled="!data.value"
                            style="text-wrap: nowrap"
                            >View Report</b-button
                        >
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import Header from '@/components/Header.vue';
import { mapGetters } from 'vuex';
import { validatePermission } from '../../utils/auth';

export default {
    name: 'CustomerComplianceRisk',
    components: {
        Header,
    },
    data() {
        return {
            blacklist: false,
            pep: false,
            conduct: true,
            blacklistComment: '',
            deceased: false,
            deceasedComment: '',
            pepComment: '',
            conductComment: '',
            loading: false,
            scans: [],
            scanSelected: null,

            data: {
                id: 0,
                isTrueMatch: true,
                pep: true,
                pepConfirmed: true,
                pepRelationTypeId: 0,
                riskTypeId: null,
                riskTypeDecisionComments: '',
                supervisorReviewComments: '',
                requiresSupervisorReview: false,
                completedBy: 0,
            },
        };
    },
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
        }),
        hasPermissionCheckbox() {
            return validatePermission('/Customer-ComplianceAndRisk-Edit-Deceased-Blacklisted-Pep');
        },
    },
    methods: {
        changeReview() {
            if (this.data.isTrueMatch) {
                this.data.requiresSupervisorReview = true;
            }
            if (this.data.pep || this.data.isTrueMatch) {
                this.data.riskTypeId = 3;
            }
        },

        changeRiskType() {
            if (this.data.riskTypeId === 3 || this.data.riskTypeId === 2) {
                this.data.requiresSupervisorReview = true;
            }
        },

        async getScans() {
            this.scans = await this.$store.dispatch('customer/scans/get_scanById', this.customer.id);
            this.scanSelected = this.scans[0];

            this.data = {
                id: this.scanSelected?.id,
                isTrueMatch: this.scanSelected?.isTrueMatch,
                pep: this.scanSelected?.pep,
                pepRelationTypeId: this.scanSelected?.pepRelationTypeId,
                riskTypeId: this.scanSelected?.riskTypeId,
                supervisorReviewComments: this.scanSelected?.supervisorReviewComments,
                riskTypeDecisionComments: this.scanSelected?.riskTypeDecisionComments,
                requiresSupervisorReview:
                    this.scanSelected?.requiresSupervisorReview || this.customer?.riskTypeDescription == 'HIGH',
                completedBy: this.scanSelected?.completedBy,
            };

            if (this.data.pep || this.data.isTrueMatch) {
                this.data.riskTypeId = 3;
            }
        },
        async saveData() {
            this.loading = true;
            await this.$store.dispatch('customer/update_customer', {
                ...this.customer,
                pep: this.pep,
                pepComments: this.pepComment,
                blacklisted: this.blacklist,
                blacklistedComments: this.blacklistComment,
                isDeceased: this.deceased,
                deceasedInformation: this.deceasedComment,
            });
            await this.$store.dispatch('customer/get_customer', this.$route.params.customer);
            this.loading = false;
        },

        async verifyClient() {
            this.loading = true;
            await this.$store.dispatch('customer/scans/createReview', {
                firstName: this.customer.firstName,
                lastName: this.customer.lastName,
                middleName: this.customer.middleName,
                customerId: this.customer.id,
                email: this.customer.email1,
            });
            await this.getScans();
            this.loading = false;
        },

        async completeReview() {
            this.loading = true;
            await this.$store.dispatch('customer/scans/completeReview', {
                ...this.data,
                id: this.scanSelected.id,
                customerId: this.customer.id,
            });
            await this.getScans();
            this.loading = false;
        },
        async updateReview() {
            this.loading = true;
            if (this.data.pep || this.data.isTrueMatch) {
                this.data.riskTypeId = 3;
            }

            if (this.data.riskTypeId === 3 || this.data.riskTypeId === 2) {
                this.data.requiresSupervisorReview = true;
            }

            await this.$store.dispatch('customer/scans/updateReview', {
                ...this.data,
                id: this.scanSelected.id,
                customerId: this.customer.id,
            });

            await this.getScans();
            await this.$store.dispatch('customer/get_customer', this.$route.params.customer);
            this.setData();
            this.loading = false;
        },
        async getReport(data) {
            const id = data.id;
            if (!id) return;
            const response = await this.$store.dispatch('auth/getSignedUrl', {
                id,
                resourceType: 'customer-compliance',
            });
            if (response?.dataResult) {
                const url = response.dataResult;
                window.open(url, '_blank');
            }
        },

        setData() {
            this.blacklist = this.customer.blacklisted;
            this.pep = this.customer.pep;
            this.blacklistComment = this.customer.blacklistedComments;
            this.pepComment = this.customer.pepComments;
            this.deceased = this.customer.isDeceased;
            this.deceasedComment = this.customer.deceasedInformation;
        },
    },

    mounted() {
        this.getScans();
        this.setData();
    },
};
</script>
