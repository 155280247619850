<template>
    <div>
        <b-card no-body class="d-flex flex-column" style="row-gap: 16px">
            <div v-if="!readOnly">
                <Header />
                <hr class="m-0" />
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <b-form-group class="mb-2 w-25">
                    <b-input-group>
                        <b-form-input
                            size="sm"
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                        ></b-form-input>

                        <b-input-group-append>
                            <b-button variant="danger" size="sm" :disabled="!filter" @click="filter = ''"
                                >Clear</b-button
                            >
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <div class="d-flex" v-if="!readOnly && hasPermission">
                    <b-button @click="showAddModal" variant="primary">Add</b-button>
                </div>
            </div>
            <div>
                <!-- Data Table -->
                <b-table
                    :style="selectBoxStyle"
                    show-empty
                    responsive
                    small
                    class="mb-0"
                    head-variant="light"
                    :items="employments"
                    :filter="filter"
                    :fields="fields"
                    hover
                >
                    <template #cell(id)="data"> #{{ data.item.id }} </template>
                    <template #cell(fromDate)="data"> {{ formatDate(data.item.fromDate) }} </template>
                    <template #cell(toDate)="data"> {{ formatDate(data.item.toDate) }} </template>
                    <template #cell(verificationDate)="data"> {{ formatDate(data.item.verificationDate) }} </template>
                    <template #cell(isActive)="data">
                        <b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
                        <b-badge variant="danger" v-else>Archived</b-badge>
                    </template>
                    <template #cell(monthlySalary)="data">
                        {{ $formatCurrency(data.item.monthlySalary) }}
                    </template>

                    <template #cell(isVerified)="data">
                        <b-badge
                            variant="warning"
                            v-if="data.item.isVerified == null && data.item.verificationDate != null"
                            >Incomplete</b-badge
                        >
                        <b-badge variant="secondary" v-else-if="data.item.isVerified == null">Pending</b-badge>
                        <b-badge variant="success" v-else-if="data.item.isVerified">Verified</b-badge>
                        <b-badge variant="danger" v-else>Invalidate</b-badge>
                    </template>

                    <template #cell(action)="data">
                        <b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
                            <b-dropdown-item v-b-modal.modal-user @click="editEmployment(data.item)"
                                >Edit Employment</b-dropdown-item
                            >
                            <b-dropdown-item v-b-modal.modal-user-details @click="viewEmployment(data.item)"
                                >View Employment</b-dropdown-item
                            >
                            <b-dropdown-item @click="showModalConfimation(data.item)" v-if="data.item.isActive"
                                >Archive</b-dropdown-item
                            >
                            <b-dropdown-item @click="updateStatus(true, data.item)" v-else>Activate</b-dropdown-item>
                            <b-dropdown-item @click="showModalRemove(data.item)">Remove</b-dropdown-item>
                            <!-- <b-dropdown-item @click="updateVerified(false, data.item)" v-if="data.item.isVerified"
                                >Unverify</b-dropdown-item
                            >
                            <b-dropdown-item @click="updateVerified(true, data.item)" v-else>Verify</b-dropdown-item> -->
                        </b-dropdown>
                    </template>
                </b-table>
                <!-- End Section -->

                <!-- Pagination -->
                <div class="pt-2 d-flex align-items-center">
                    <b-form-group
                        label="Per page"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="9"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            @input="getEmployments"
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            @input="getEmployments"
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
                <!-- End Pagination -->
            </div>
        </b-card>

        <!-- ---------------------------------------- -->
        <!-- register modal -->
        <!-- ---------------------------------------- -->
        <b-modal
            id="modal-register"
            :title="isEditing ? 'Edit Employment' : 'Add Employment'"
            header-bg-variant="light"
            hide-footer
            centered
            @close="clearValues"
        >
            <b-form @submit.prevent="addEmployer">
                <b-card-text>
                    <div class="mb-2">
                        <label class="h6">Search a business</label>
                        <Select
                            :items="options"
                            @option-selected="handleOption"
                            @search-input="handleSearchInput"
                            :defaultOption="{
                                id: employerSelected.employerId,
                            }"
                        />
                    </div>
                    <b-form-group
                        label="Job Description"
                        label-for="job-description"
                        label-class="fw-medium"
                        class="mb-2"
                    >
                        <b-form-input
                            id="job-description"
                            type="text"
                            size="sm"
                            placeholder="Job Description"
                            v-model="jobDescription"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Hire Date" label-for="from-date" label-class="fw-medium" class="mb-2">
                        <b-form-input
                            id="from-date"
                            type="date"
                            size="sm"
                            placeholder="Hire Date"
                            v-model="fromDate"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="End Date" label-for="to-date" label-class="fw-medium" class="mb-2">
                        <b-form-input
                            id="to-date"
                            type="date"
                            size="sm"
                            placeholder="End Date"
                            v-model="toDate"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label="Monthly Salary"
                        label-for="monthly-salary"
                        label-class="fw-medium"
                        class="mb-2"
                    >
                        <b-form-input
                            id="monthly-salary"
                            type="number"
                            size="sm"
                            step="0.01"
                            placeholder="Monthly Salary"
                            v-model="salary"
                            required
                            min="0"
                        ></b-form-input>
                    </b-form-group>

                    <b-button block :disabled="loadingAddEmployment" variant="primary" type="submit">
                        <b-spinner small v-if="loadingAddEmployment"></b-spinner>
                        <span v-else>Save Employment</span>
                    </b-button>
                </b-card-text>
            </b-form>
        </b-modal>
        <!-- ---------------------------------------- -->
        <!-- end register modal -->
        <!-- ---------------------------------------- -->

        <!-- ---------------------------------------- -->
        <!-- confirmation modal -->
        <!-- ---------------------------------------- -->
        <b-modal id="modal-confirmation" title="Confirmation" header-bg-variant="light" hide-footer size="sm" centered>
            <b-card-text>
                <div class="text-center mb-3 mt-3">
                    <feather type="trash" class="feather-lg text-danger"></feather>
                    <h5 class="mb-0 mt-3">Are you sure you want to archive this employment?</h5>
                </div>

                <b-button block variant="danger" class="mt-5" @click="updateStatus(false, employerSelected)">
                    <b-spinner small v-if="loading"></b-spinner>
                    <span v-else>Archive Employment</span>
                </b-button>
                <b-button
                    block
                    variant="outline-secondary"
                    class="mt-3 border-0"
                    @click="$bvModal.hide('modal-confirmation')"
                    >Cancel</b-button
                >
            </b-card-text>
        </b-modal>
        <!-- ---------------------------------------- -->
        <!-- end confirmation modal -->
        <!-- ---------------------------------------- -->

        <!-- Modal View -->
        <b-modal id="modal-view" title="View Employment" hide-footer centered>
            <b-card-text>
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Employer Name</h6>
                    <span class="mail-desc d-block text-muted">{{ employerSelected.employerName }}</span>
                </div>
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Employer Address</h6>
                    <span class="mail-desc d-block text-muted">{{ employerSelected.employerAddress }}</span>
                </div>
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Employer Telephone Number</h6>
                    <span class="mail-desc d-block text-muted">{{ employerSelected.employerTelephoneNumber }}</span>
                </div>
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Job Description</h6>
                    <span class="mail-desc d-block text-muted">{{ employerSelected.jobDescription }}</span>
                </div>
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Hire Date</h6>
                    <span class="mail-desc d-block text-muted">{{ formatDate(employerSelected.fromDate) }}</span>
                </div>
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">End Date</h6>
                    <span class="mail-desc d-block text-muted">{{ formatDate(employerSelected.toDate) }}</span>
                </div>

                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Monthly Salary</h6>
                    <span class="mail-desc d-block text-muted" v-if="employerSelected">{{
                        $formatCurrency(employerSelected.monthlySalary)
                    }}</span>
                </div>
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Comment</h6>
                    <span class="mail-desc d-block text-muted">{{ employerSelected.verificationComments || '' }}</span>
                </div>
                <div class="d-flex justify-content-end mt-4">
                    <b-button variant="secondary" @click="$bvModal.hide('modal-view')">Cancel</b-button>
                </div>
            </b-card-text>
        </b-modal>

        <!-- ---------------------------------------- -->
        <!-- remove confirmation modal -->
        <!-- ---------------------------------------- -->
        <b-modal
            id="modal-remove-confirmation"
            title="Confirmation"
            header-bg-variant="light"
            hide-footer
            size="sm"
            centered
        >
            <b-card-text>
                <div class="text-center mb-3 mt-3">
                    <feather type="trash" class="feather-lg text-danger"></feather>
                    <h5 class="mb-0 mt-3">
                        Are you sure you want to remove {{ employerSelected.employerName }} employment ?
                    </h5>
                </div>

                <b-button block variant="danger" class="mt-5" @click="removeEmployment">
                    <b-spinner small v-if="loading"></b-spinner>
                    <span v-else>Remove Employment</span>
                </b-button>
                <b-button
                    block
                    variant="outline-secondary"
                    class="mt-3 border-0"
                    @click="$bvModal.hide('modal-remove-confirmation')"
                    >Cancel</b-button
                >
            </b-card-text>
        </b-modal>
    </div>
</template>
<script>
import Header from '@/components/Header.vue';
import * as dayjs from 'dayjs';
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import Select from '../Select.vue';

export default {
    name: 'CustomerEmployment',
    components: {
        Header,
        Select,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        page: {
            title: 'employment',
        },
        query: '',
        debouncedSearch: null,
        options: [],
        filter: null,
        fields: [
            {
                key: 'id',
                sortable: true,
            },
            {
                key: 'employerName',
                label: 'Employer Name',
                sortable: true,
            },
            {
                key: 'jobDescription',
                label: 'Job Description',
                sortable: true,
            },
            {
                key: 'fromDate',
                label: 'Hire Date',
                sortable: true,
            },
            {
                key: 'toDate',
                label: 'End Date',
                sortable: true,
            },
            {
                key: 'verificationDate',
                label: 'Verification Date',
                sortable: true,
            },
            {
                key: 'monthlySalary',
                label: 'Monthly Salary',
                sortable: false,
            },
            {
                key: 'isVerified',
                label: 'Verified',
                sortable: true,
            },
            {
                key: 'isActive',
                label: 'Status',
                sortable: true,
            },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],

        name: '',
        address: '',
        phone: '',
        jobDescription: '',
        fromDate: '',
        toDate: null,
        salary: '',
        loading: false,
        loadingAddEmployment: false,

        employerSelected: {},
        isEditing: false,
        customers: [],
        selectedOption: null,
    }),
    methods: {
        showModalRemove(item) {
            this.employerSelected = item;
            this.$bvModal.show('modal-remove-confirmation');
        },

        async showModalConfimation(item) {
            this.employerSelected = item;
            this.$bvModal.show('modal-confirmation');
        },

        Delete() {
            this.items = this.items.filter((item) => item.id !== '123');
        },
        showAddModal() {
            this.clearValues();
            this.$bvModal.show('modal-register');
        },
        clearValues() {
            this.name = '';
            this.address = '';
            this.phone = '';
            this.jobDescription = '';
            this.fromDate = '';
            this.toDate = null;
            this.salary = '';
            this.selectedOption = null;
            this.isEditing = false;
        },
        formatDate(date) {
            if (!date) return;

            return dayjs(date).format('YYYY-MM-DD');
        },
        getEmployments() {
            this.loading = true;
            let data = {
                skip: this.currentPage,
                take: this.perPage,
                customerId: this.$route.params.customer,
            };
            this.$store.dispatch('customer/employments/get_employments', data);
            this.loading = false;
        },

        async addEmployer() {
            if (!this.selectedOption) {
                this.$bvToast.toast('Please select an employer', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return;
            }

            this.loadingAddEmployment = true;
            let data = {
                employerId: this.selectedOption?.id || null,
                jobDescription: this.jobDescription,
                fromDate: this.fromDate,
                toDate: this.toDate || null,
                monthlySalary: this.salary,
                customerId: this.$route.params.customer,
            };

            if (this.isEditing) {
                data.id = this.employerSelected.id;
                data.employerId = this.selectedOption?.id || null;
                await this.$store.dispatch('customer/employments/update_employment', data);
                this.isEditing = false;
            } else {
                await this.$store.dispatch('customer/employments/add_employment', data);
            }

            //clear data
            this.clearValues();

            await this.getEmployments();
            this.$bvModal.hide('modal-register');
            this.loadingAddEmployment = false;
        },
        async viewEmployment(item) {
            this.employerSelected = await this.$store.dispatch('customer/employments/get_employmentById', item.id);
            this.$bvModal.show('modal-view');
        },

        async editEmployment(item) {
            this.isEditing = true;
            this.employerSelected = await this.$store.dispatch('customer/employments/get_employmentById', item.id);

            this.name = this.employerSelected.employerName;
            this.address = this.employerSelected.employerAddress;
            this.phone = this.employerSelected.employerTelephoneNumber;
            this.jobDescription = this.employerSelected.jobDescription;
            this.toDate = this.formatDate(this.employerSelected.toDate);
            this.fromDate = this.formatDate(this.employerSelected.fromDate);
            this.salary = this.employerSelected.monthlySalary;

            if (this.employerSelected.employerId != 0 && this.employerSelected.employerId != null) {
                const response = await this.$store.dispatch('customer/get_reference', this.employerSelected.employerId);
                if (response) {
                    this.selectedOption = response;
                    this.options = [
                        {
                            text: response.businessName,
                            value: response.id,
                            prependText: response.businessId,
                        },
                    ];
                }
            }

            this.$bvModal.show('modal-register');
        },
        async updateStatus(status, item) {
            this.loading = true;
            let data = {
                id: item.id,
                employerName: item.employerName,
                employerAddress: item.employerAddress,
                employerTelephoneNumber: item.employerTelephoneNumber,
                jobDescription: item.jobDescription,
                isActive: status,
                modifiedBy: 1,
            };
            await this.$store.dispatch('customer/employments/update_employmentStatus', data);
            await this.getEmployments();
            this.loading = false;
            this.$bvModal.hide('modal-confirmation');
        },
        async updateVerified(status, item) {
            let data = {
                id: item.id,
                employerName: item.employerName,
                employerAddress: item.employerAddress,
                employerTelephoneNumber: item.employerTelephoneNumber,
                jobDescription: item.jobDescription,
                isVerified: status,
                modifiedBy: 1,
                loanApplicationId: this.$route.params.customer,
            };
            await this.$store.dispatch('customer/employments/update_employmentVerification', data);
            await this.getEmployments();
        },
        async removeEmployment() {
            this.loading = true;
            await this.$store.dispatch('customer/employments/delete_employment', this.employerSelected.id);
            await this.getEmployments();
            this.loading = false;
            this.$bvModal.hide('modal-remove-confirmation');
        },
        async getCustomer() {
            const response = await this.$store.dispatch('customer/get_allBusiness', {
                skip: 1,
                take: 20,
                query: this.query,
            });
            this.customers = response?.dataResult ?? [];
            this.options = this.customers.map((el) => {
                return {
                    text: `${el.businessName} ${el.doingBusinessName ? `/ ${el.doingBusinessName}` : ''}`,
                    value: el.id,
                    prependText: el.businessId,
                };
            });
        },
        handleOption(item) {
            this.selectedOption = this.customers.find((el) => el.id == item.value);
        },
        handleSearchInput(query) {
            this.query = query;
            this.debouncedSearch();
        },
    },
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
            employments: 'customer/employments/getEmployments',
            authorizations: 'auth/getAuthorizations',
        }),
        hasPermission() {
            return this.authorizations.includes('/Customer-Update-AdditionalDetails');
        },
        selectBoxStyle() {
            return {
                minHeight: this.readOnly ? 'auto' : '250px', // min-height condicional
            };
        },
    },
    mounted() {
        if (!this.new) {
            this.getEmployments();
        }

        if (!this.readOnly && this.hasPermission) {
            this.fields.push({
                key: 'action',
                label: 'Actions',
                sortable: false,
            });
        }
    },
    created() {
        this.debouncedSearch = debounce(this.getCustomer, 300);
    },
};
</script>
